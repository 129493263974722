import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/layouts/DashboardLayout/DashboardLayout";


const StockManagement = () => {
    return (
        <DashboardLayout
            title={"Gestion de Stock"}
            selectedPath={RouteIdEnum.Stock}
        >
          
        </DashboardLayout>
    );
};

export default StockManagement;
