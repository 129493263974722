import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { IPromotionCodesData } from "./PromotionCodes.types";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PercentIcon from '@mui/icons-material/Percent';
import moment from "moment";
import dayjs, { Dayjs } from 'dayjs';
import EuroIcon from '@mui/icons-material/Euro';
import StatusBadge from "components/common/StatusBadge/StatusBadge";
import { isTimeStampsDateBetweenTwoDays,isTimeStampsDateBetweenTwoDays2 } from "utils/helpers/global.helpers";
import AddButton from "components/common/AddButton/AddButton";
import SearchInput from "components/common/SearchInput/SearchInput";
import {Checkbox} from "@mui/material";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
} from "./PromotionCodes.style";
import { Delete, Edit } from "@mui/icons-material";
import usePromotionCodesList from "./usePromotionCodesList";
import DeletePromotionCode from "../PromotionCodeDelete/DeletePromotionCode";
import { useCallback, useEffect, useState } from "react";
import AddPromotionCode from "../PromotionCodeAdd/AddPromotionCode";
import EditPromotionCode from "../PromotionCodeEdit/EditPromotionCode";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import { useDeleteMultiplePromotionCodesMutation } from "redux/api/promotionCodes/promotionCodes";
import DeletePromotionCodeMultiple from "../PromotionCodeDeleteMultiple/DeletePromotionCodeMultiple";
import { useSnackBar } from "hooks/useSnackBar";

function PromotionCodes() {
  const { t } = useTranslation();

  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    promotionCodesData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
  } = usePromotionCodesList();
  const [deleteCode, { data, error,reset,isLoading:loading }] =
    useDeleteMultiplePromotionCodesMutation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deletedCode, setDeletedCode] = useState<{
    id: number;
    code: string;
  }>();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setSelectAll(!selectAll);

  };
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [openDeleteMultipleModal,setOpenDeleteMultipleModal]=useState(false)
  const openDeleteDialogStatus = useCallback(
    (id: number, code: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedCode({ id: id, code: code });
    },
    [setOpenDeleteDialog, setDeletedCode]
  );
  const openDeleteMultipleDialogStatus =() => {
      setOpenDeleteMultipleModal(true);

  };

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("promotion_codes.successfully_deleted"));
    reset();

  }
  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogStatus = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);

  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [editedCodeInfo, setEditedCodeInfoCode] = useState<{
    id: number;
    code: string;
    link:string;
    categories:string;
    subCategories:string;
    type:string;
    isLimitedusers:string;
    isLinkedUsers:boolean;
    isUserExist:string;
    isUserNotExist:boolean;
    linkcodesubscription:boolean;
    subscriptions:string;
    isLinkedPromoSubscription:boolean;
    isDiscountMin:boolean;
    isUnlimitedUsers:boolean;
    nbUser:string;
    discount:string;
    email:string;
    products:string;
    percentage: number;
    percentage_value:string;
    eur_value:string;
    start_date: string;
    end_date: string;
    client:string;
    affectation:string;
  }>();

  const openEditingDialogStatus = useCallback(
    (
      id: number,
      code: string,
      link:string,
      categories:string,
      subCategories:string,
      type:string,
      isLimitedusers:string,
      isLinkedUsers:boolean,
      isUserExist:string,
      isUserNotExist:boolean,
      linkcodesubscription:boolean,
      subscriptions:string,
      isLinkedPromoSubscription:boolean,
      isDiscountMin:boolean,
      isUnlimitedUsers:boolean,
      nbUser:string,
      discount:string,
      email:string,
      products:string,
      percentage: number,
      percentage_value:string,
      eur_value:string,
      start_date: string,
      end_date: string,
      client:string,
      affectation:string
      ) =>
      () => {
        console.log(id,code,
        percentage,
        start_date,
        end_date,
        link,
        categories,
        subCategories,
        type,
        isLimitedusers,
        isLinkedUsers,
        isUserExist,
        isUserNotExist,
        linkcodesubscription,
        subscriptions,
        isLinkedPromoSubscription,
        isDiscountMin,
        isUnlimitedUsers,
        nbUser,
        discount,
        email,
        products,
        percentage_value,
        eur_value,
        client,affectation )
        setEditedCodeInfoCode({ id,code,
        percentage,
        start_date,
        end_date,
        link,
        categories,
        subCategories,
        type,
        isLimitedusers,
        isLinkedUsers,
        isUserExist,
        isUserNotExist,
        linkcodesubscription,
        subscriptions,
        isLinkedPromoSubscription,
        isDiscountMin,
        isUnlimitedUsers,
        nbUser,
        discount,
        email,
        products,
        percentage_value,
        eur_value,
        client,affectation });
        setOpenEditingDialog(true);
      },
    []
  );

  const deleteMultiple=(event:any)=>{
    event.preventDefault()
    deleteCode({
      codes_ids:selectedItems,
      select_all:selectAll,
      search:search
  })
  if(!error)
  setOpenDeleteMultipleModal(false)
  }

  return (
    <DashboardLayout
      title={t("menu.promotion_codes")}
      selectedPath={RouteIdEnum.PromotionCodes}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>

        <ButtonContainerStyle item xs={12} sm={4}>
        {((selectAll || selectedItems.length != 0) )&&<DeleteButton  onAddClick={openDeleteMultipleDialogStatus} title={t("common.delete")} />
       }
         &nbsp;
          <AddButton
            onAddClick={openAddingDialogStatus}
            title={t("common.add")}
          />
        </ButtonContainerStyle>
      </Grid>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "checkbox",
            title: <Checkbox  color="secondary" checked={selectAll} onChange={handleSelectAll} />,
          },
          {
            key: "code",
            title: t("promotion_codes.code"),

            onClick: onOrderChange("code"),
            asc: orderBy === "code" ? orderDir === "asc" : undefined,
          },
          {
            key: "percentage_value",
            title: t("Type de promotion"),
            onClick: onOrderChange("percentage_value"),
            asc: orderBy === "percentage_value" ? orderDir === "asc" : undefined,
          },
          {
            key: "start_date",
            title: t("promotion_codes.start_date"),
          },
          {
            key: "end_date",
            title: t("promotion_codes.end_date"),
          },

          {
            key: "status",
            title: t("common.status"),
          },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={promotionCodesData?.data.map((e: IPromotionCodesData) => {
          return {
            checkbox: (
              <Checkbox  checked={(!selectAll && selectedItems.includes(e.id))||(selectAll && !selectedItems.includes(e.id))}
              onChange={() => handleCheckboxChange(e.id)}
              />

            ),
            code: <p>{e.code}</p>,
            percentage_value: (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                {e.type ? (
                  e.type === "product_percent" ? (
                    <PercentIcon />
                  ) : e.type === "product_value" ? (
                    <EuroIcon />
                  ) : e.type === "free_shipping" ? (
                    <LocalShippingIcon />
                  ) : null
                ) : (
                  "0"
                )}
              </Box>
            ),
            
            start_date: (
              <div>
                {new Intl.DateTimeFormat('en-GB', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hourCycle: 'h23', // Forces 24-hour time format
                }).format(new Date(Number(e.start_date) * 1000))}
              </div>
            ),
            end_date: (
              <div>
                {new Intl.DateTimeFormat('en-GB', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hourCycle: 'h23', // Forces 24-hour time format
                }).format(new Date(Number(e.end_date) * 1000))}
              </div>
            ),
            
            status: (
              <StatusBadge
                isEnabled={isTimeStampsDateBetweenTwoDays2(
                  Number(e.start_date),
                  Number(e.end_date)
                )}
              />
            ),
            action: (
              <ActionContainerStyle>
                {
                  <Tooltip title={t("common.edit")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openEditingDialogStatus(
                        e.id,
                        e.code,
                        e.link,
                        e.categories,
                        e.subCategories,
                        e.type,
                        e.isLimitedusers,
                        e.isLinkedUsers,
                        e.isUserExist,
                        e.isUserNotExist,
                        e.linkcodesubscription,
                        e.subscriptions,
                        e.isLinkedPromoSubscription,
                        e.isDiscountMin,
                        e.isUnlimitedUsers,
                        e.nbUser,
                        e.discount,
                        e.email,
                        e.products,
                        e.percentage,
                        e.percentage_value,
                        e.eur_value,
                        e.start_date,
                        e.end_date,
                        e.client,
                        e.affectation

                      )}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDeleteDialogStatus(e.id, e.code)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }
              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: promotionCodesData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
      {deletedCode && (
        <DeletePromotionCode
          id={deletedCode.id}
          code={deletedCode.code}
          openDeleteDialog={openDeleteDialog}
          getDeletedCodeAfterUpdate={(code:any)=>setDeletedCode(undefined)}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeletePromotionCode>
      )}
      {selectedItems && (
        <DeletePromotionCodeMultiple
        isLoading={loading}
          codes={selectedItems}
          openDeleteMultipleDialog={openDeleteMultipleModal}
          setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
          onSubmit={deleteMultiple}
        ></DeletePromotionCodeMultiple>
      )}
      <AddPromotionCode
        openAddingDialog={openAddingDialog}
        setOpenAddingDialog={setOpenAddingDialog}
      ></AddPromotionCode>
      {editedCodeInfo && (
        <EditPromotionCode
          id={editedCodeInfo.id}
          codeInfos={{
            code: editedCodeInfo.code,
            percentage: editedCodeInfo.percentage,
            start_date: editedCodeInfo.start_date,
            end_date: editedCodeInfo.end_date,
            link:editedCodeInfo.link,
            categories:editedCodeInfo.categories,
            subCategories:editedCodeInfo.subCategories,
            type:editedCodeInfo.type,
            isLimitedusers:editedCodeInfo.isLimitedusers,
            isLinkedUsers:editedCodeInfo.isLinkedUsers,
            isUserExist:editedCodeInfo.isUserExist,
            isUserNotExist:editedCodeInfo.isUserNotExist,
            linkcodesubscription:editedCodeInfo.linkcodesubscription,
            subscriptions:editedCodeInfo.subscriptions,
            isLinkedPromoSubscription:editedCodeInfo.isLinkedPromoSubscription,
            isDiscountMin:editedCodeInfo.isDiscountMin,
            isUnlimitedUsers:editedCodeInfo.isUnlimitedUsers,
            nbUser:editedCodeInfo.nbUser,
            discount:editedCodeInfo.discount,
            email:editedCodeInfo.email,
            products:editedCodeInfo.products,
            percentage_value:editedCodeInfo.percentage_value,
            eur_value:editedCodeInfo.eur_value,
            client:editedCodeInfo.client,
            affectation:editedCodeInfo.affectation
          }}
          openEditingDialog={openEditingDialog}
          setOpenEditingDialog={setOpenEditingDialog}
        ></EditPromotionCode>
      )}
    </DashboardLayout>
  );
}
export default PromotionCodes;
